<template>
  <ui-dialog :title="$store.state.location.name + ' bearbeiten'" ref="dialog" @confirm="confirm()" size="wide" confirmLabel="Speichern">
    <ui-field label="Titel der Location">
      <ui-form-input v-model="$store.state.location.name"/>
    </ui-field>
  </ui-dialog>
</template>

<script>
export default {
  name: "EditLocation",
  data(){
    return {

    }
  },
  methods: {
    async confirm(){
      await this.$store.updateLocation(this.$store.state.location)
    },
    async open(id){
      await this.$store.getLocation(id).then(this.$refs.dialog.open())
    }
  }
}
</script>

<style scoped>

</style>