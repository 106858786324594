<template>
  <ui-dialog title="Neue Session" ref="dialog" @confirm="confirm()" size="wide" confirmLabel="Erstellen">
    <ui-field label="Name der Session">
      <ui-form-input v-model="session.name"/>
    </ui-field>
    <ui-field label="Location">
      <ui-form-dropdown :options="sessionOptions" v-model="session.locationId" v-if="sessionOptions.length > 0"/>
    </ui-field>
    <ui-field label="Notizen">
      <ui-form-input type="multiline" v-model="session.description"/>
    </ui-field>
    <ui-field label="Datum">
      <ui-form-datepicker v-model="session.date" />
    </ui-field>
    <ui-field label="Uhrzeit">
      <ui-form-dropdown :options="timeslotOptions" v-model="session.timeSlotId"/>
    </ui-field>
    <ui-field label="Start" v-if="session.timeSlotId === 'new'">
      <ui-form-timepicker v-model="time.start"/>
    </ui-field>
    <ui-field label="Ende" v-if="session.timeSlotId === 'new'">
      <ui-form-timepicker v-model="time.end"/>
    </ui-field>
  </ui-dialog>
</template>

<script>
import {session} from "@/store/controllers/sessions";

export default {
  name: "NewSession",
  data(){
    return {
      session: {
      },
      time: {
        start: '',
        end: ''
      }
    }
  },
  mounted(){
    this.$store.getLocations()
    this.$store.getTimeslots()
    this.session = Object.assign(this.session, session)
  },
  computed: {
    sessionOptions(){
      return this.$store.state.locations.map(a => {
        return {
          value: a.id,
          label: a.name
        }
      })
    },
    timeslotOptions(){
      let opt = this.$store.state.timeslots.map(a => {
        return {
          value: a.id,
          label: a.start
        }
      })
      opt.unshift({
        label: 'Neue Uhrzeit',
        value: 'new'
      })
      return opt
    }
  },
  methods: {
    async confirm(){

      if(this.session.timeSlotId === 'new'){
        const newTimeslot = await this.$store.createTimeslot({
          start: this.session.date + ' ' + this.time.start,
          end: this.session.date + ' ' + this.time.end,
        })
        if(newTimeslot !== null) this.session.timeSlotId = newTimeslot.id
      }
      this.session.type = 'WORKSHOP'
      await this.$store.createSession(this.session)
    },
    open(){
      this.$refs.dialog.open()
    }
  }
}
</script>

<style scoped>

</style>