<template>
  <ui-dialog title="Neue Location" ref="dialog" @confirm="confirm()" size="wide" confirmLabel="Erstellen">
    <ui-field label="Name der Location">
      <ui-form-input v-model="location.name"/>
    </ui-field>
    <ui-field label="Adressdaten" v-if="typeof setAddress === 'undefined'">
      <ui-buttons>
        <ui-button @click="setAddress = 'select'" v-if="addressOptions.length > 0">
          Auswählen
        </ui-button>
        <ui-button @click="setAddress = 'new'">
          Neu erstellen
        </ui-button>
      </ui-buttons>
    </ui-field>
    <div v-else-if="setAddress === 'select'">
      <ui-field label="Adresse">
              <ui-form-dropdown :options="addressOptions" v-model="location.addressId" v-if="addressOptions.length > 0"/>
      </ui-field>
    </div>
    <div v-else-if="setAddress = 'new'">
      <hr class="my-2">
      <ui-field label="Straße und Nr.">
        <ui-form-input v-model="address.address"/>
      </ui-field>
      <ui-field label="PLZ">
        <ui-form-input v-model="address.zip"/>
      </ui-field>
      <ui-field label="Ort">
        <ui-form-input v-model="address.city"/>
      </ui-field>
      <ui-field label="Land">
        <ui-form-dropdown :options="countryOptions" v-model="address.country"/>
      </ui-field>
      <hr class="my-2">
    </div>
    <ui-field label="Notizen">
      <ui-form-input type="multiline" v-model="location.description"/>
    </ui-field>

  </ui-dialog>
</template>

<script>
import {location} from "@/store/controllers/location";
import {address} from "@/store/controllers/address";

export default {
  name: "NewLocation",
  data(){
    return {
      location: {
      },
      address:{
      },
      setAddress: undefined,
      countryOptions: [
        {
          label: 'Deutschland',
          value: 'Germany'
        }
      ]
    }
  },
  mounted(){
    this.$store.getAddresses()
    this.location = Object.assign(this.location, location)
    this.address = Object.assign(this.address, address)
  },
  computed: {
    addressOptions(){
      return this.$store.state.addresses.map(a => {
        return {
          value: a.id,
          label: a.address
        }
      })
    }
  },
  methods: {
    async confirm(){
      this.location.status = 'ACTIVE'
      if(this.setAddress === 'new'){
        const newAddress = await this.$store.createAddress(this.address)
        if(newAddress !== null) this.location.addressId = newAddress
      }
      await this.$store.createLocation(this.location)
    },
    open(){
      this.$refs.dialog.open()
    }
  }
}
</script>

<style scoped>

</style>