<template>
  <div>
    <ui-toolbar :actions="toolbar"></ui-toolbar>
    <ui-cards>
      <ui-card v-for="(location, index) in $store.state.locations" :key="index">
        <template v-slot:head>{{location.name}}</template>
        {{location.description}}
        <hr class="my-2">
        Addresse: <br>
        {{ getAddress(location.addressId).address }}

        <template v-slot:foot><a class="cursor-pointer" @click="() => $refs.editLocation.open(location.id)"><i class="fal fa-edit"></i></a></template>
      </ui-card>
    </ui-cards>
  </div>
  <new-location ref="newLocation" />
  <edit-location ref="editLocation" />
</template>

<script>
import NewLocation from "@/components/Admin/dialogs/NewLocation";
import EditLocation from "@/components/Admin/dialogs/EditLocation";

export default {
  name: "LocationsView",
  components: {
    NewLocation,
    EditLocation
  },
  data(){
    return {
      event: {},
      toolbar: [
        {
          title: 'Neue Location',
          label: `<i class="fa fa-plus"></i>`,
          component: 'button',
          type: 'success',
          action: () => this.$refs.newLocation.open()
        }
      ]
    }
  }, created() {

    this.$store.getLocations()
  },
  methods: {
    getAddress(id){
      return this.$store.state.addresses.find(a => a.id = id) || {}
    }
  }
}
</script>

<style scoped>

</style>
