<template>
  <ui-dialog :title="$store.state.session.name + ' bearbeiten'" ref="dialog" @confirm="confirm()" size="wide" confirmLabel="Speichern">
    <ui-field label="Titel der Session">
      <ui-form-input v-model="$store.state.session.name"/>
    </ui-field>
  </ui-dialog>
</template>

<script>
export default {
  name: "EditSession",
  data(){
    return {

    }
  },
  methods: {
    async confirm(){
      await this.$store.updateSession(this.$store.state.session)
    },
    async open(id){
      await this.$store.getSession(id).then(this.$refs.dialog.open())
    }
  }
}
</script>

<style scoped>

</style>