<template>
  <div>
    <h2 class="c-view-h2">{{$store.state.event.name}}</h2>
    <h1 class="c-view-h1">Einstellungen</h1>
    <div class="c-tab-bar">
      <ul>
        <li :class="tabLiClass(0)">
          <a :class="tabClass(0)" @click="activeTab = 0">Allgemeines</a>
        </li>
        <li :class="tabLiClass(1)">
          <a :class="tabClass(1)" @click="activeTab = 1">Sessions</a>
        </li>
        <li :class="tabLiClass(2)">
          <a :class="tabClass(2)" @click="activeTab = 2">Locations</a>
        </li>
<!--        <li :class="tabLiClass(2)">-->
<!--          <a :class="tabClass(2)" @click="activeTab = 2">Transfers</a>-->
<!--        </li>-->
<!--        <li>-->
<!--          <a class="disabled">Unterkünfte</a>-->
<!--        </li>-->
      </ul>
    </div>
    <div class="tab-content bg-white p-4 border rounded-b rounded-r">
      <SettingsView v-if="activeTab === 0" />
      <SessionsView v-else-if="activeTab === 1" />
      <LocationsView v-else-if="activeTab === 2" />
    </div>
  </div>
</template>

<script>

import SettingsView from "./Settings/Settings"
import SessionsView from "./Settings/Sessions"
import LocationsView from "./Settings/Locations"

export default {
  name: "Settings",
  components: {
    SettingsView,
    SessionsView,
    LocationsView
  },
  data(){
    return {
      activeTab: 0,
    }
  },
  methods:{
    tabClass(i){
      return {
        active: this.activeTab === i,
          inactive: this.activeTab !== i
      }
    },
    tabLiClass(i){
      return {
        '-mb-px': this.activeTab === i,
      }
    }
  }
}
</script>

<style scoped>

</style>
