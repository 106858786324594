<template>
  <div>
    <ui-toolbar :actions="toolbar"></ui-toolbar>
    <h3>Allgemeines</h3>
    <ui-field label="Titel der Veranstaltung">
      <ui-form-input v-model="event.name"/>
    </ui-field>
    <ui-field label="Veranstaltungsslug">
      <ui-form-input v-model="event.slug"/>
    </ui-field>
    <ui-field label="Start">
      <ui-form-datepicker v-model="event.startDate" />
    </ui-field>
    <ui-field label="Ende">
      <ui-form-datepicker v-model="event.endDate"/>
    </ui-field>
    <ui-field label="Abonnentenliste">
      <ui-form-input v-model="event.mwSubscriberListId"/>
    </ui-field>
    <ui-field label="Notizen (intern)">
      <ui-form-input type="multiline" v-model="event.internalNotes"/>
    </ui-field>
    <hr class="my-4">
    <ui-buttons>
      <ui-button @click="$refs.eventArchive.open()">Event archivieren</ui-button>
      <ui-button @click="$refs.eventDelete.open()">Event löschen</ui-button>
    </ui-buttons>
    <ui-dialog ref="eventArchive" @confirm="archiveEvent()">Wollen Sie dieses Event wirklich archivieren.</ui-dialog>
    <ui-dialog ref="eventDelete" @confirm="deleteEvent()">Wollen Sie dieses Event wirklich löschen. Dieser Vorgang kann nicht rückgängig gemacht werden.</ui-dialog>
  </div>
</template>

<script>
export default {
  name: "SettingsView",
  data(){
    return {
      event: {},
      toolbar: [
        {
          title: 'Speichern',
          label: `<i class="fa fa-save"></i>`,
          component: 'button',
          type: 'info',
          action: async () => await this.$store.updateEvent(this.event).then(this.$uiToast.add("gespeichert"))
        }
      ],
      options: [
        {
          value: '23',
          label: 'label'
        }
      ]
    }
  }, created() {
    this.event = this.$store.state.event
  },
  computed: {
  },
  methods: {
    async deleteEvent(){
      await this.$store.deleteEvent(this.$store.state.event.id)
      this.$store.clearEvent()
      this.$store.clearEvents()
      this.$router.push({ name: 'AdminEvents' })
    },
    async archiveEvent(){
      this.$store.state.event.status = 'ARCHIVE'
      await this.$store.updateEvent(this.$store.state.event)
      this.$store.clearEvent()
      this.$store.clearEvents()
      this.$router.push({ name: 'AdminEvents' })
    }
  }
}
</script>

<style scoped>

</style>
